import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CHECKOUT_SUCCESS } from 'ui/page/checkout-page/events';
import { GET_ORDER_DETAILS } from 'graphql/sales/order';
import { GET_STORE_CONFIG, IStoreConfig } from 'graphql/config/config';
import { useQuery, useLazyQuery } from '@apollo/client';
import { getSessionStorageData } from 'ui/util/session-storage';
import { Join } from 'ui/component/join';
import { Divider } from 'ui/component/checkout/components/divider';
import { Newsletter } from 'ui/component/newsletter';
import { PublishEvent } from '@silkpwa/module/app-event-bus/publish-event';
import { StandardLoadingImage } from 'ui/component/loading-image';
import styles from 'ui/page/checkout-page/style.css';
import { OrderDetails } from './order-details';

export const SuccessStep = () => {
    const [storeConfig, setStoreConfig] = React.useState<IStoreConfig | null>(null);
    const [getConfig] = useLazyQuery(GET_STORE_CONFIG, {
        onCompleted: (data) => {
            setStoreConfig(data?.storeConfig);
        },
    });
    const initConfig = async () => {
        await getConfig();
    };
    useEffect(() => {
        initConfig();
    }, []);
    const isDesktop = useMediaQuery({ query: '(min-width: 1041px)' });
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    const orderNumber = pathParts[pathParts.length - 1];
    const cartId = typeof getSessionStorageData('lastOrderedCart', true) === 'string' ? getSessionStorageData('lastOrderedCart', true) : null;
    const {
        data,
        error,
        loading,
    } = useQuery(GET_ORDER_DETAILS,
        {
            variables: {
                orderNumber,
                cartId,
            },
        });
    const isGuest = data?.getOrderDetails?.customer_is_guest;
    const showJoin = isGuest && storeConfig?.loyalty_config.isLoyaltyProgramEnabled && !storeConfig?.is_portal;
    return (
        <>
            {data?.getOrderDetails && storeConfig && (
                <>
                    <div className={styles.checkoutGridLeft}>
                        <OrderDetails order={data.getOrderDetails} />
                    </div>
                    <div className={styles.checkoutGridRight}>
                        {isDesktop && (
                            <>
                                {showJoin && (
                                    <>
                                        <Join orderId={data?.getOrderDetails?.entity_id} />
                                        <Divider />
                                        <Newsletter />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <PublishEvent eventName={CHECKOUT_SUCCESS} eventData={data.getOrderDetails} fireOnce />
                </>
            )}
            {(loading || !storeConfig) && <StandardLoadingImage />}
            {error && <h1>{`Error: ${error.message}`}</h1>}
        </>
    );
};
